<template>
  <div id="user-management" class="col-12">
    <v-row no-gutters>
      <v-col cols="12" class="user-page-title main-content">
        <span>Create New User</span>
      </v-col>
    </v-row>
    <v-row no-gutters id="user-form">
      <v-col cols="12" class="main-content">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="submit"
          autocomplete="off"
        >
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-card class="box-height height-responsive" outlined tile>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">Username</p>
                      <v-text-field
                        v-model="userName"
                        required
                        autocomplete="off"
                        :rules="userNameRules"
                        placeholder="New Username"
                        filled
                        :validate-on-blur="true"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pt-2">
                    <v-col cols="12" md="12">
                      <p class="label">User Group Type</p>
                      <v-select
                        v-model="userGroup"
                        :items="groups"
                        item-text="name"
                        item-value="id"
                        :rules="userGroupRules"
                        filled
                        placeholder="Select User Group Type"
                        append-icon="fas fa-caret-down"
                        :validate-on-blur="true"
                        :class="`${userGroup ? 'custom-hide-input' : ''}`"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card class="box-height height-responsive" outlined tile>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">Password</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="password"
                        placeholder="Password"
                        required
                        :type="showPass ? 'text' : 'password'"
                        autocomplete="off"
                        :rules="passwordRules"
                        :validate-on-blur="true"
                        filled
                      >
                        <template v-slot:append>
                          <a
                            class="mt-eye"
                            @click="showPass = !showPass"
                            v-html="
                              renderIcon(
                                showPass,
                                'i',
                                'fas fa-eye',
                                'fas fa-eye-slash'
                              )
                            "
                          >
                          </a>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pt-2">
                    <v-col cols="12" md="12">
                      <p class="label">Confirm Password</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="confirmPass"
                        placeholder="Confirm Password"
                        required
                        :type="showConfirm ? 'text' : 'password'"
                        autocomplete="off"
                        :rules="confirmPassRules"
                        :validate-on-blur="true"
                        filled
                      >
                        <template v-slot:append>
                          <a
                            class="mt-eye"
                            @click="showConfirm = !showConfirm"
                            v-html="
                              renderIcon(
                                showConfirm,
                                'i',
                                'fas fa-eye',
                                'fas fa-eye-slash'
                              )
                            "
                          >
                          </a>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card class="box-height height-responsive" outlined tile>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">Email Address</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="email"
                        placeholder="New User Email Address"
                        required
                        autocomplete="off"
                        :rules="emailRules"
                        :validate-on-blur="true"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <p class="label">&nbsp;</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <button type="submit" class="submit-button">
                        Add User
                      </button>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row no-gutters class="table-section">
      <v-col cols="12" class="user-page-title main-content">
        <span>View Users</span>
      </v-col>
      <v-col cols="1" md="0" sm="0"></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="main-content">
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="pageSize"
          :search="search"
          @pagination="updatePagination"
          no-data-text="No Data"
          :footer-props="footer"
        >
          <template v-slot:top>
            <v-row no-gutters id="table-search">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  placeholder="Search Users"
                  class="search-table width-search-input"
                  append-icon="fas fa-search"
                  autocomplete="off"
                  rounded
                  outlined
                  filled
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
          </template>

          <template v-slot:[`item.no`]="{ index }">
            {{ index + 1 + (currentPage - 1) * pageSize }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn :ripple="false" icon class="mr-3">
              <v-img
                alt="Users"
                class="shrink mr-2"
                contain
                src="../../assets/tables/button_key_white.svg"
                transition="scale-transition"
                @click="showNewPassDialog(item)"
              />
            </v-btn>
            <v-btn
              :ripple="false"
              icon
              v-if="item.status == 'locked'"
              class="mr-3"
            >
              <v-img
                alt="Users"
                class="shrink mr-2"
                contain
                src="../../assets/tables/button_lock.svg"
                transition="scale-transition"
                @click="
                  openConfirmDialog(
                    item,
                    unlockUser,
                    'You are about to unlock user'
                  )
                "
              />
            </v-btn>
            <v-btn :ripple="false" icon class="mr-3">
              <v-img
                alt="Users"
                class="shrink mr-2"
                contain
                src="../../assets/tables/Button_edit.svg"
                transition="scale-transition"
                @click="openDialog(item)"
              />
            </v-btn>
            <v-btn :ripple="false" icon>
              <v-img
                alt="Users"
                class="shrink mr-2"
                contain
                src="../../assets/tables/Button_delete.svg"
                transition="scale-transition"
                @click="
                  openConfirmDialog(
                    item,
                    deleteUser,
                    'You are about to delete user'
                  )
                "
              />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="0" sm="0" />
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      content-class="user-edit-dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>Edit User</v-card-title>
        <v-card-text>
          <v-form
            ref="editForm"
            v-model="valid"
            @submit.prevent="submitEdit"
            autocomplete="off"
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-card outlined tile style="border: none !important">
                  <v-card-text
                    class="box-height"
                    style="height: auto !important"
                  >
                    <v-row no-gutters>
                      <v-col cols="12">
                        <p class="label">Username</p>
                        <v-text-field
                          v-model="editUsername"
                          required
                          autocomplete="off"
                          :rules="userNameRules"
                          placeholder="New Username"
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card outlined tile style="border: none !important">
                  <v-card-text
                    class="box-height"
                    style="height: auto !important; padding-top: 0"
                  >
                    <v-row no-gutters>
                      <v-col cols="12">
                        <p class="label">Email Address</p>
                        <v-text-field
                          v-model="editEmail"
                          placeholder="New User Email Address"
                          required
                          autocomplete="off"
                          :rules="emailEditRules"
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card outlined tile style="border: none !important">
                  <v-card-text
                    class="box-height"
                    style="height: auto !important; padding-top: 0"
                  >
                    <v-row no-gutters>
                      <v-col cols="12">
                        <p class="label">User Group Type</p>
                        <v-select
                          v-model="editUserGroup"
                          :items="groups"
                          item-text="name"
                          item-value="id"
                          :rules="userGroupRules"
                          filled
                          append-icon="fas fa-caret-down"
                          placeholder="Select User Group Type"
                          :class="`${editUserGroup ? 'custom-hide-input' : ''}`"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" class="text-right">
                <button
                  class="btn btn-secondary btn-custom"
                  type="button"
                  :ripple="false"
                  @click="closeDialog"
                >
                  Cancel
                </button>
                <button
                  class="ml-4 btn btn-primary btn-custom"
                  type="button"
                  :ripple="false"
                  @click="revertEdit"
                >
                  Revert
                </button>
                <button
                  class="ml-4 btn btn-primary btn-custom"
                  :disabled="!valid"
                  :ripple="false"
                  type="submit"
                >
                  Save
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="700px"
      content-class="user-edit-dialog"
    >
      <v-card>
        <v-card-title>Confirm</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12 pt-4">
              <span>{{ dialogMessage + " " + userItem.userName }}</span>
            </v-col>
            <v-col cols="12 pt-4" class="text-right">
              <button
                class="btn btn-secondary btn-custom"
                type="button"
                :ripple="false"
                @click="closeConfirmDialog"
              >
                CANCEL
              </button>
              <button
                class="ml-4 btn btn-primary btn-custom"
                type="button"
                :ripple="false"
                @click="dialogAction(userItem)"
              >
                YES
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="changePassDialog"
      persistent
      max-width="600"
      content-class="user-edit-dialog"
    >
      <v-card>
        <v-card-title>CHANGE PASSWORD</v-card-title>
        <v-card-text style="margin-top: 20px">
          <v-form
            ref="editPass"
            @submit.prevent="submitPass"
            v-model="passValid"
          >
            <v-row no-gutters>
              <v-col cols="12" md="4" class="text-left pr-2 pt-4">
                <span>New Password</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="newPassword"
                  required
                  :type="showNewPass ? 'text' : 'password'"
                  autocomplete="off"
                  :rules="changePasswordRules"
                  outlined
                  placeholder="New Password"
                >
                  <template v-slot:append>
                    <a
                      style="margin-top: 2px"
                      @click="showNewPass = !showNewPass"
                      v-html="
                        renderIcon(
                          showNewPass,
                          'i',
                          'fas fa-eye',
                          'fas fa-eye-slash'
                        )
                      "
                    >
                    </a>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col cols="12" md="4" class="text-left pr-2 pt-4">
                <span>Confirm Password</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="confirmNewPass"
                  required
                  :type="showConfirmNewPass ? 'text' : 'password'"
                  autocomplete="off"
                  :rules="newConfirmPassRules"
                  outlined
                  placeholder="Confirm Password"
                >
                  <template v-slot:append>
                    <a
                      style="margin-top: 2px"
                      @click="showConfirmNewPass = !showConfirmNewPass"
                      v-html="
                        renderIcon(
                          showConfirmNewPass,
                          'i',
                          'fas fa-eye',
                          'fas fa-eye-slash'
                        )
                      "
                    >
                    </a>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12 pt-4">
                <span>Are you sure you want to change the password?</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3 pt-2">
              <v-spacer></v-spacer>
              <v-col cols="12" class="text-right">
                <button
                  class="btn btn-secondary btn-custom"
                  :ripple="false"
                  type="button"
                  @click="closePassDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 btn btn-primary btn-custom"
                  :ripple="false"
                  type="submit"
                  :disabled="!passValid"
                >
                  YES
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12 pt-4" style="padding-top: 6px">
              <div v-html="apiMessage"></div>
              <div v-if="messUsername != null" style="margin-top: 20px;">
                <h5>{{ messUsername }}</h5>
                <h5>{{ messPassword }}</h5>
              </div>
            </v-col>
            <v-col cols="12 pt-4" class="text-right">
              <button
                class="btn btn-primary btn-custom"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" src="../../assets/css/user-management.scss"></style>
<script>
import _ from "lodash";
import { footerProps } from "../../constants";
import { groupService, userService } from "../../services";
export default {
  components: {},
  data() {
    return {
      showConfirm: false,
      showPass: false,
      userName: "",
      userGroup: "",
      email: "",
      password: "",
      confirmPass: "",
      editUsername: "",
      editUserGroup: "",
      editEmail: "",
      userInfo: null,
      search: "",
      newPassword: "",
      confirmNewPass: "",
      showNewPass: false,
      showConfirmNewPass: false,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      messUsername: null,
      messPassword: null,
      total: 0,
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          filterable: false,
        },
        { text: "User Name", value: "userName" },
        { text: "Email Address", value: "email" },
        { text: "User Group", value: "group" },
        { text: "Registered", value: "created_at" },
        {
          text: "Actions",
          value: "actions",
          width: 150,
          sortable: false,
          filterable: false,
        },
      ],
      users: [],
      groups: [],
      pageSize: 4,
      currentPage: 1,
      valid: false,
      userNameRules: [
        (v) => !!v || "Username is required",
        (v) => /^\S+$/.test(v) || "Username must not contain whitespace",
      ],
      emailRules: [
        (v) => !!v || (!this.email ? true : "Email is required"),
        (v) => /.+@.+\..+/.test(v) || (!this.email ? true : "Email must be valid"),
      ],
      emailEditRules: [
        (v) => !!v || (!this.editEmail ? true : "Email is required"),
        (v) => /.+@.+\..+/.test(v) || (!this.editEmail ? true : "Email must be valid"),
      ],
      passwordRules: [
        (v) => {
          if(v){
            if (v.length < 8) return "Password must be at least 8 characters";
            if (!/(?=.*[A-Z])/.test(v)) return "Password must contain at least 1 uppercase letter";
            if (!/(?=.*[!@#$%^&+=*_-])/.test(v)) return "Password must contain at least 1 special character";
            if (!/(?=.*\d)/.test(v)) return "Password must contain at least 1 number";
            return true;
          }
          return true;
        }
      ],
      changePasswordRules: [
        (v) => {
          if (!v) return "Password is required";
          if (v.length < 8) return "Password must be at least 8 characters";
          if (!/(?=.*[A-Z])/.test(v)) return "Password must contain at least 1 uppercase letter";
          if (!/(?=.*[!@#$%^&+=*_-])/.test(v)) return "Password must contain at least 1 special character";
          if (!/(?=.*\d)/.test(v)) return "Password must contain at least 1 number";
          return true;
        }
      ],
      userGroupRules: [(v) => !!v || "User Group is required"],
      dialog: false,
      footer: {},
      confirmDialog: false,
      userItem: { userName: "" },
      changePassDialog: false,
      passValid: false,
      dialogAction: () => null,
      dialogMessage: "",
    };
  },
  created() {
    this.footer = Object.assign({}, this.footer, footerProps);
    this.getListGroups().then(() => {
      this.getListUsers();
    });
  },
  mounted() {
    this.userName = null;
  },
  methods: {
    updatePagination(pagination) {
      this.currentPage = pagination.page;
      this.pageSize = pagination.itemsPerPage;
      this.$set(
        this.footer,
        "pageText",
        `${this.currentPage} of ${this.total}`
      );
    },
    getListGroups() {
      return groupService
        .getGroupList()
        .then((res) => {
          if (res?.data?.data?.length) {
            const data = res.data.data;
            this.groups = [...data];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    getListUsers() {
      userService.getListUsers().then((res) => {
        if (res?.data?.data?.length) {
          const data = res.data.data;
          data.map((val) => {
            val.userName = val.username;
            val.group = this.getGroupName(val.user_group_id);
          });
          this.users = [...data];
          this.total = Math.ceil((res.data.data.length || 0) / this.pageSize);
        }
      });
    },
    getGroupName(groupId) {
      if (!this.groups?.length || !groupId) {
        return "";
      }
      const group = _.find(this.groups, (obj) => obj.id == groupId);
      return group?.name || "";
    },
    submit() {
      if (this.$refs.form.validate()) {
        const data = this.prepareData(
          this.userName,
          this.userGroup,
          this.email,
          this.password,
        );
        userService
          .storeUser(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            let data_user = res.data.data;
            this.getListUsers();
            this.apiMessage = "User has been successfully created";
            this.apiTitle = "Success";
            this.messUsername = "Username: " + data_user.username;
            this.messPassword = "Password: " + data_user.password;
            this.revert();
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "User cannot be created";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.handleMessageProfile();
            this.messageDialog = true;
          });
      }
    },
    revert() {
      this.$refs.form.reset();
    },
    prepareData(userName, userGroup, email, password) {
      const data = {
        username: userName,
        user_group_id: userGroup,
      };
      if(email){
        data.email = email ? email : null;
      }
      if (password) {
        data.password = password;
      }
      return data;
    },
    unlockUser(item) {
      if (item?.id) {
        userService
          .unlockUser(item.id)
          .then((res) => {
            if (res.status !== 200) throw res;
              this.userItem = Object.assign({}, this.userItem, { userName: "" });
              this.closeConfirmDialog();
              this.getListUsers();
              this.apiMessage = "User has been successfully unlocked";
              this.apiTitle = "Success";
              this.handleMessageProfile();
              this.messageDialog = true;
          })
          .catch((err) => {
            let message = "User cannot be unlocked";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
              this.apiMessage = message;
              this.apiTitle = "Error";
              this.handleMessageProfile();
              this.messageDialog = true;
          });
      }
    },
    openDialog(item) {
      if (item?.id) {
        userService
          .getUser(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setUserInfo(res.data.data);
              this.dialog = true;
            }
          })
          .catch();
      }
    },
    closeDialog() {
      this.setUserInfo(null);
      this.dialog = false;
    },
    openConfirmDialog(item, action, message) {
      this.userItem = Object.assign({}, this.userItem, item);
      this.dialogAction = action;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.userItem = Object.assign({}, this.userItem, { userName: "" });
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogMessage = "";
    },
    revertEdit() {
      this.setUserInfo(this.userInfo);
    },
    setUserInfo(data) {
      if (data) {
        this.editUserGroup = data.user_group_id || "";
        this.editUsername = data.username || "";
        this.editEmail = data.email || "";
        this.userInfo = data;
      } else {
        this.editUserGroup = "";
        this.editUsername = "";
        this.editEmail = "";
        this.userInfo = null;
      }
    },
    submitEdit() {
      const data = this.prepareData(
        this.editUsername,
        this.editUserGroup,
        this.editEmail,
        null,
      );
      userService
        .updateUser(this.userInfo.id, data)
        .then((res) => {
          if (res.status !== 200) throw res;
          this.apiMessage = "User's information has been successfully updated";
          this.apiTitle = "Success";
          this.handleMessageProfile();
          this.messageDialog = true;
          this.getListUsers();
          this.closeDialog();
        })
        .catch((err) => {
          let message = "User's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.handleMessageProfile();
          this.messageDialog = true;
        });
    },
    deleteUser(item) {
      if (item?.id) {
        userService
          .deleteUser(item.id)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.closeConfirmDialog();
            this.apiMessage = "User has been successfully deleted";
            this.apiTitle = "Success";
            this.handleMessageProfile();
            this.messageDialog = true;
            this.getListUsers();
          })
          .catch((err) => {
            let message = "User cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.handleMessageProfile();
            this.messageDialog = true;
          });
      }
    },
    showNewPassDialog(item) {
      this.userItem = Object.assign({}, this.userItem, item);
      this.changePassDialog = true;
    },
    closePassDialog() {
      this.$refs.editPass.reset();
      this.userItem = Object.assign({}, this.userItem, { userName: "" });
      this.changePassDialog = false;
    },
    submitPass() {
      const data = {
        new_password: this.newPassword,
      };
      userService
        .resetPassForUser(this.userItem.id, data)
        .then((res) => {
          if (res.status !== 200) throw res;
          this.closePassDialog();
          this.apiMessage = "User's information has been successfully updated";
          this.apiTitle = "Success";
          this.messUsername = "Username: " + this.userItem.username;
          this.messPassword = "Password: " + data.new_password;
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "User's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.handleMessageProfile();
          this.messageDialog = true;
        });
    },
    closeMessage() {
      this.messageDialog = false;
      this.handleMessageProfile();
    },
    handleMessageProfile() {
      this.messUsername = null;
      this.messPassword = null;
    },
  },
  computed: {
    confirmPassRules() {
      return [this.confirmPass === this.password || "Passwords do not match"];
    },
    newConfirmPassRules() {
      return [
        this.newPassword === this.confirmNewPass || "Passwords do not match",
      ];
    },
  },
};
</script>
